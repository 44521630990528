<template>
  <div class="ladies project-component">
    <div class="fullsection">
      <p>
        The goal of this project was to analyze an existing CI/CD pipeline, analyze its flaws, and offer a better
        alternative to implement on a demo project - here, using github actions, slack automations and cypress for
        testing.
        In the following report, you will see my approach on the issue as well as how I implemented a functional CI/CD
        pipeline for my portfolio website.
      </p>
      <br />
      <strong><a class="links animated-links" href="https://drive.google.com/file/d/1U0KWmlyk7ngDKN223S8-fppXYBKk6LP2/view?usp=sharing"
          target="_blank">Link to my
          report</a></strong>
      <br />
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/cicdportfolio/album/1.png" alt="" />
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/cicdportfolio/album/2.png" alt="" />
    </div>

    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        Understanding DevOps principles and best practices to test and deploy quality code safely.
        It was also interesting to add improvements to my existing portfolio page.
      </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Cicdportfolio",
};
</script>
<style scoped>

</style>